import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import toast from "react-hot-toast";
import signupImg from "../../Assets/kstore2.gif";
import Styles from "./Signup.module.css";
import { setCookie } from "../../Utility/Utility";
import { shopCustomersFormService } from "../../Services/ApiServices";

const phoneExp = /^[6,7,8,9]{1}[0-9]{9}$/;
let EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
function SignUp() {
  let navigate = useNavigate();
  let param = useParams();
  const [isLoader, setIsLoader] = useState(false);
  const { handleChange, handleSubmit, values, errors, touched, setValues } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        mblNumber: "",
        address: "",
      },
      validationSchema: Yup.object({
        name: Yup.string().required("Required"),
        email: Yup.string()
          .matches(EMAIL_REGEX, "Enter Valid email address")
          .email("Invalid email address")
          .required("Required"),
        mblNumber: Yup.string()
          .min(10, "Mobile number must 10 character")
          .matches(phoneExp, "Mobile number must start with 9 or 8 or 7 or 6 ")
          .required("Required"),
        address: Yup.string().required("Required"),
      }),
      onSubmit: (values) => {
        setIsLoader(true);
        handleShopCustomerForm();
      },
    });

  useEffect(() => {
    if (sessionStorage.getItem("customerlog")) {
      let userDetails = JSON.parse(sessionStorage.getItem("customerlog"));
      setValues({
        ...values,
        name: userDetails.name,
        email: userDetails.email,
        mblNumber: userDetails.mblNumber,
        address: userDetails.address,
      });
    }
  }, []);
  const handleShopCustomerForm = () => {
    let formData = new FormData();
    formData.append("franchise_id", param.id);
    formData.append("name", values.name);
    formData.append("mobile_no", values.mblNumber);
    formData.append("email", values.email);
    formData.append("address", values.address);
    shopCustomersFormService(formData)
      .then(() => {
        handleDetails();
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const handleDetails = () => {
    setCookie("franchiseid", null, null);
    sessionStorage.setItem(
      "customerlog",
      JSON.stringify({ ...values, id: param.id })
    );
    sessionStorage.setItem("id", param.id);
    navigate("/kansas_product/allproducts");
  };

  return (
    <div className={Styles.bgContainer + " container-fluid"}>
      <div className={Styles.bg}></div>
      {isLoader ? (
        <div className={Styles.loaderParent}>
          <div className={Styles.loader}></div>
        </div>
      ) : null}
      <div className={Styles.loginContainer}>
        <div className={Styles.loginLeftContainer}>
          <img className={Styles.loginImg} src={signupImg} alt="login" />
        </div>

        <div className={Styles.loginRightContainer}>
          <div>
            <h3 className="kstoreFont">
              Welcome to <span className="orange">KSTORe</span>{" "}
            </h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className={Styles.labelName + " mt-4"}>
                <label>Name</label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput}
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              {touched.name && errors.name ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.name}
                </div>
              ) : null}
              <div className={Styles.labelName + " mt-3"}>
                <label>e-Mail</label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput}
                    type="text"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    placeholder="Enter Your e-Mail"
                  />
                </div>
              </div>
              {touched.email && errors.email ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.email}
                </div>
              ) : null}

              <div className={Styles.labelName + " mt-3"}>
                <label>Phone Number</label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput}
                    type="text"
                    inputMode="numeric"
                    name="mblNumber"
                    maxLength={10}
                    onKeyDown={(e) => {
                      if (
                        !(
                          parseInt(e.key) === 0 ||
                          parseInt(e.key) ||
                          e.key === "Backspace"
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                    value={values.mblNumber}
                    placeholder="Enter Mobile Number"
                  />
                </div>
              </div>
              {touched.mblNumber && errors.mblNumber ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.mblNumber}
                </div>
              ) : null}

              <div className={Styles.labelName + " mt-3"}>
                <label>Address</label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput}
                    type="text"
                    name="address"
                    onChange={handleChange}
                    value={values.address}
                    placeholder="Enter Address"
                  />
                </div>
              </div>
              {touched.address && errors.address ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.address}
                </div>
              ) : null}
              <div className="text-center">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className={Styles.loginBtn + " mt-4"}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
